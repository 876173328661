// Colors
$primary               : #C44E57;
$secondary             : #1B1B1C;
$secondary80           : #38393B;
$secondary60           : #56585B;
$secondary40           : #B6B6B6;
$secondary20           : #f5f5f5;
$tertiary              : hsl(40, 92%, 56%);

$navigation-text-color : $secondary;

// Misc
$navigation-width      : 280px;
$radius                : 4px;

$error                 : red;
$success               : green;
$notice                : orange;
