table.overview {
    min-width       : 100%;
    border-collapse : collapse;

    tbody tr:hover {
        td {
            background : rgba(black, .02);
            cursor     : pointer;

            table.clean {
                td {
                    background: transparent;
                }
            }
        }
    }

    th,
    td {
        overflow      : hidden;
        padding       : 24px 12px;
        font-size     : 16px;
        font-weight   : 500;
        line-height   : 24px;
        text-align    : left;
        white-space   : nowrap;
        text-overflow : ellipsis;

        &:first-child {
            padding-left : 0 !important;
        }
    }

    th {
        color : rgba(black, .4);
    }

    td {
        border-top : 1px solid rgba(black, .05);

        &.align-right {
            display   : flex;
            flex-flow : row-reverse;
        }
    }

    .status {
        display       : inline-block;
        border-radius : 50%;
        width         : 8px;
        height        : 8px;
        background    : $secondary40;

        &.enabled {
            background : #52AE32;
        }

        &.disabled {
            background : #E40615;
        }
    }
}


table.clean {
    td {
        border-top: none;
        padding: 0 12px;
    }
}

