.content {
    display   : flex;
    flex-flow : row nowrap;

    .left {
        flex-grow : 3;
        margin    : 0 28px 0 0;
    }

    .right {
        flex-grow : 1;
        margin    : 0 0 0 28px;
        max-width : 500px;
    }

    &.equal {
        .left {
            flex   : 1 1 0;
            margin : 0 16px 0 0;
        }

        .right {
            flex      : 1 1 0;
            margin    : 0 0 0 16px;
            max-width : unset;
        }
    }
}
