.nav {
    position   : fixed;
    top        : 0;
    bottom     : 0;
    left       : 0;
    box-shadow : 0px 4px 10px rgba(0, 0, 0, 0.04);
    width      : $navigation-width;
    height     : 100vh;
    background : #FFFFFF;

    .logo {
        display         : flex;
        align-items     : center;
        justify-content : center;
        box-sizing      : border-box;
        width           : 100%;
        height          : 88px;
        padding         : 16px;
        background      : #FFFFFF;

        img {
            width      : 70%;
            height     : 100%;
            object-fit : contain;
        }
    }

    &-items {
        display        : flex;
        flex-direction : column;
        height         : calc(100% - 174px);
        padding        : 72px 16px 16px 16px;

        > a {
            display         : flex;
            margin-bottom   : 16px;
            border-radius   : $radius;
            width           : 100%;
            height          : 48px;
            padding         : 0 16px;
            font-size       : 18px;
            line-height     : 48px;
            text-decoration : none;
            color           : $navigation-text-color;
            transition      : background-color .2s ease-out;

            &:not(.with-info) {
                font-weight : 600;
            }

            img {
                margin-right : 16px;
                width: 24px;
            }

            &:hover {
                background : $secondary20;
            }

            &.active {
                background : $primary;
                color      : white;

                img {
                    filter : invert(1) brightness(1.5);
                }
            }

            &:hover {
                .nav-link-info {
                    opacity        : 1;
                    pointer-events : all;
                }
            }

            .nav-link-info {
                position       : absolute;
                top            : 0;
                left           : 0;
                display        : flex;
                align-items    : center;
                flex-flow      : row nowrap;
                opacity        : 0;
                box-shadow     : 0 4px 10px rgba(0, 0, 0, 0.04);
                border         : 1px solid rgba(0, 0, 0, .1);
                border-radius  : 4px;
                width          : 100%;
                padding        : 0 16px 0 16px;
                background     : white;
                pointer-events : none;
                transition     : opacity .2s ease-out, background-color .2s ease-out, color .2s ease-out;

                img {
                    margin-right : 8px;
                }

                &:hover {
                    background : #FBFBFB;
                    color      : black;
                }
            }
        }

        .profile {
            position    : relative;
            margin-top  : auto;
            outline     : none;
            height      : 56px;
            cursor      : pointer;
            font-size   : 16px;
            font-weight : 500;
            line-height : 56px;
            color       : rgba($navigation-text-color, .6);

            .account {
                border        : 1px solid rgba(0, 0, 0, 0.1);
                border-radius : 4px;
                width         : 100%;
                height        : 56px;
                padding       : 0 16px;

                &::after {
                    content    : '';
                    position   : absolute;
                    top        : 21px;
                    right      : 16px;
                    width      : 10px;
                    height     : 14px;
                    background : url('/images/icons/dropdown.svg');
                }
            }

            .drop-down-menu {
                position      : absolute;
                top           : -56px;
                display       : none;
                border        : 1px solid rgba(0, 0, 0, 0.1);
                border-bottom : none;
                border-radius : 4px;
                width         : 100%;

                a {
                    display         : inline-block;
                    width           : 100%;
                    height          : 56px;
                    padding         : 0 16px;
                    cursor          : pointer;
                    font-size       : 16px;
                    font-weight     : 500;
                    line-height     : 56px;
                    text-decoration : none;
                    color           : $navigation-text-color;
                }
            }

            &.show {
                .drop-down-menu {
                    display : block;
                }
            }
        }
    }

    &-notes {
        padding: 16px 16px 32px 16px;

        a {
            display: flex;
            margin-bottom: 16px;
            width: 100%;
            height: 48px;
            padding: 0 16px;
            font-size: 18px;
            line-height: 48px;
            text-decoration: none;
            color: $navigation-text-color;
            transition: background-color .2s ease-out;
            font-weight: 600;

            img {
                margin-right: 16px;
                width: 24px;
            }

            &:hover {
                background: $secondary20;
            }
        }
    }
}
