.row {
    display : flex;
    width   : 100%;
}

.two-columns {
    display               : grid;
    grid-template-columns : 1fr 420px;
    gap                   : 56px;
}

.column {
    margin-bottom : 56px;
    box-shadow    : 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius : 8px;
    padding       : 40px 24px;
    background    : white;

    h2 {
        padding-bottom : 24px;
    }

    &.full-width {
        width: 100%;
    }

    &.full-height {
        height: 100%;
        min-height: 100%;
    }
}

// Helpers
.space-between {
    justify-content : space-between;
}

.mb-32 {
    margin-bottom : 32px;
}

.no-padding {
    padding : 0;
}

.clickable {
    cursor : pointer;
}
