.custom-pagination {
    display       : flex;
    box-shadow    : 0 4px 10px rgba(0, 0, 0, 0.04);
    border        : 2px solid #ffffff;
    border-radius : 8px;
    width         : fit-content;
    padding       : 8px 0 8px 0;
    background    : #ffffff;

    ul {
        display         : flex;
        margin          : 0 0 0 0;
        padding         : 0 0 0 0;
        list-style-type : none !important;

        li {
            padding     : 0 8px;
            font-weight : 600;

            a {
                color           : $primary;
                text-decoration : none;

                &:hover {
                    color : $secondary;
                }
            }

            &.disabled {
            }

            &.active {
                font-weight : 700;
                color       : #000;
            }
        }

    }
}
