* {
    position   : relative;
    box-sizing : border-box;
}

#app {
    position : absolute;
    z-index  : 1;
    width    : 100%;
}

body {
    margin                  : 0;
    padding                 : 0;
    background              : #F5F5F5;
    font-family             : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    line-height             : 1;
    color                   : $secondary;
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
}

hr {
    margin     : 16px 0 16px 0;
    border     : none;
    border-top : 1px solid rgba(0, 0, 0, 0.1);
}

main {
    left    : $navigation-width;
    width   : calc(100% - #{$navigation-width});
    padding : 80px 80px 104px 80px;

    header {
        display         : flex;
        align-items     : flex-start;
        flex-flow       : column nowrap;
        justify-content : space-between;
        margin-bottom   : 32px;
        width           : 100%;

        .filters-and-buttons {
            display         : flex;
            flex-flow       : column nowrap;
            justify-content : space-between;

            & > * {
                margin : 0 0 16px 0;

                &:last-child {
                    margin : 0 0 0 0;
                }
            }
        }

        &.overview {
            flex-flow : row nowrap;

            a {
                border-radius    : 4px;
                padding          : 0 16px;
                background-color : $primary;
                font-weight      : bold;
                line-height      : 48px;
                text-decoration  : none;
                color            : white;
            }
        }

        .subtitle {
            margin-top : 8px;
        }

        .pagination-indicator {
            margin-top : 24px;
        }
    }
}

.row {
    display     : flex;
    align-items : center;
    flex-flow   : row nowrap;

    .import-export-container {
        label {
            align-items     : center;
            display         : flex;
            justify-content : center;

            svg {
                cursor : pointer;
            }
        }

        a.with-margin {
            margin : 0 15px
        }

        input {
            display : none;
        }
    }
}

.max-editions {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : center;
    margin          : 24px 0 24px 0;

    img {
        opacity : .6;
        margin  : 0 16px 0 0;
    }
}

.align-top {
    vertical-align: top;
}

/**
Removes HTML/code option from Redactor text editor
 */
.redactor-toolbar-wrapper .re-html {
    display : none;
}
