.collapsible {
    margin      : 16px 0;
    font-weight : 600;
    cursor      : pointer;

    .title {
        width         : 100%;
        border        : 1px solid rgba(0, 0, 0, 0.7);
        border-radius : $radius;
        padding       : 16px;
    }

    &.active {
        .content {
            display : block;
        }
    }

    .content {
        display : none;
        padding : 32px;
    }
}

.collapsible-option {
    margin      : 16px 0 32px 0;
    font-weight : 600;
    cursor      : pointer;

    .title {
        display         : flex;
        font-size       : 16px;
        font-weight     : 400;
        justify-content : space-between;
        width           : 100%;

        .open {
            display : none;
        }

        .closed {
            display : block;
        }
    }

    &.active {
        .content {
            display : block;
        }

        .title {
            .open {
                display : block;
            }

            .closed {
                display : none;
            }
        }
    }

    .content {
        display : none;
        padding : 16px 0;

        .form-group {
            align-items   : center;
            display       : flex;
            margin-bottom : 5px;

            .checkbox {
                input[type=checkbox] {
                    width              : 16px;
                    height             : 16px;
                    border             : 1px solid #C44E57;
                    box-shadow         : none;
                    font-size          : 2em;
                    -webkit-appearance : none;
                    -moz-appearance    : none;
                    -o-appearance      : none;
                    appearance         : none;
                    box-sizing         : border-box;
                    padding            : 0;
                    cursor             : pointer;

                    &:checked {
                        background-color : #C44E57;
                    }
                }

                svg {
                    width           : 8px;
                    margin          : auto;
                    top             : 0;
                    position        : absolute;
                    left            : 0;
                    right           : 0;
                    bottom          : 0;
                    pointer-events  : none;
                }
            }

            label {
                color       : #000000;
                margin-left : 10px;
            }
        }
    }

    &:not(:last-child) {
        border-bottom : 1px solid rgba(0, 0, 0, .1);
    }
}
