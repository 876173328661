table.information {
    text-align : left;

    tbody {
        tr {
            th,
            td {
                height      : 48px;
                font-size   : 14px;
                line-height : 24px;
                color       : hsl(0, 0%, 10%, .7);
            }

            th {
                width       : 30%;
                font-weight : 500;
            }

            td {
                color : $secondary40;
            }
        }
    }
}
