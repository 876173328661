.price-matrix {
    tr {
        th,
        td {
            &.add {
                &-duration,
                &-mileage {
                    .btn {
                        width : 100%;
                    }
                }
            }
        }
    }
}