.flash-messages {
    position       : fixed;
    top            : 0;
    right          : 50%;
    padding-top    : 56px;
    max-width      : 440px;
    z-index        : 4;
    animation      : flash-message-from-top 3s both 1s;
    pointer-events : none;
    transform : translateX(50%);

    @media (max-width : 992px) {
        left      : 0;
        max-width : unset;
        padding   : 48px 32px;
        animation : flash-message-from-top 3s both 1s;
    }

    .flash-message {
        text-align    : center;
        display       : block;
        color         : white;
        font-size     : 16px;
        font-weight   : 600;
        line-height   : 1.3;
        box-shadow    : 0 2px 8px 0 rgba(0, 0, 0, 0.05), 0 20px 26px -10px rgba(0, 0, 0, 0.2);
        border-radius : 6px;
        padding       : 12px 24px;
        margin-bottom : 16px;

        @media (max-width : 992px) {
            font-size  : 14px;
            box-shadow : 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 22px 26px -10px rgba(0, 0, 0, 0.15);
        }

        &.error {
            background-color : $error;
        }

        &.success {
            background-color : $success;
        }
        
        &.notice {
            background-color : $notice;
        }
    }
}

@keyframes flash-message {
    0% {
        opacity   : 0;
        transform : translateX(100%);
    }
    10% {
        opacity   : 1;
        transform : translateX(0);
    }
    90% {
        opacity   : 1;
        transform : translateX(0);
    }
    100% {
        opacity   : 0;
        display   : none;
        transform : translateX(100%);
    }
}

@keyframes flash-message-from-top {
    0% {
        opacity   : 0;
        transform : translateX(50%) translateY(-100%);
    }
    15% {
        opacity   : 1;
        transform : translateX(50%) translateY(0);
    }
    90% {
        opacity   : 1;
        transform : translateX(50%) translateY(0);
    }
    100% {
        opacity   : 0;
        display   : none;
        transform : translateX(50%) translateY(-100%);
    }
}