*:focus {
    outline : none;
}

.search {
    display       : flex;
    border        : 1px solid rgba(black, .1);
    border-radius : 4px;
    max-width     : 455px;
    height        : 48px;
    background    : white;

    label {
        img {
            padding : 14px 15px;
        }
    }

    input {
        border     : none;
        width      : 100%;
        height     : 100%;
        background : transparent;
    }
}

.row:not(.not-even) .form-group {
    flex : 1 1 0;

    & ~ .form-group {
        margin-left : 16px;
    }

    &--small {
        flex: 1 1;
    }

    &--wide {
        flex: 3 1;
    }
}

.form-group {
    display       : block;
    margin-bottom : 24px;

    label {
        margin      : 0;
        width       : 100%;
        font-size   : 12px;
        font-weight : 500;
        line-height : 1.5;
        color       : grey;
    }

    input,
    select,
    textarea {
        border           : 1px solid rgba(0, 0, 0, 0.1);
        border-radius    : 4px;
        width            : 100%;
        height           : 48px;
        padding-left     : 18px;
        background-color : white;
        font-size        : 14px;
        font-weight      : 500;
        color            : #1A1A1A;

        &:disabled {
            background : #e8e8e8;
            cursor     : not-allowed;
            color      : grey;
        }

        &::placeholder {
            color : rgba(#1a1a1a, .32)
        }
    }

    input[type=color] {
        border       : none;
        padding-left : 0;

        &:focus {
            outline : none;
        }
    }

    textarea {
        height  : 104px;
        padding : 18px;
        resize  : none;
    }

    &.has-errors {
        input,
        select,
        textarea {
            border-color : $error;
        }
    }

    &.without-margin-bottom {
        margin-bottom : 0;
    }

    .error {
        color : $error;
    }
}

.lightswitch-input {
    display       : flex;
    align-items   : center;
    margin-bottom : 24px;

    label {
        margin      : 0;
        width       : 100%;
        font-size   : 12px;
        font-weight : 500;
        line-height : 1.5;
        color       : grey;
    }

    .lightswitch {
        overflow      : hidden;
        border-radius : 32px;
        width         : 56px;
        height        : 32px;

        .checkbox {
            z-index : 3;
            opacity : 0;
            margin  : 0;
            width   : 100%;
            height  : 100%;
            padding : 0;
            cursor  : pointer;

            &:checked + .knobs:before {
                content : '';
                left    : 19px;
            }

            &:checked ~ .layer {
                background-color : #52AE32;
            }
        }

        .knobs {
            position : absolute;
            z-index  : 2;
            top      : 0;
            left     : 0;

            &:before {
                content          : '';
                position         : absolute;
                top              : 2px;
                left             : 2px;
                box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.15);
                border-radius    : 50%;
                width            : 28px;
                height           : 28px;
                background-color : white;
                transition       : 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
            }
        }

        .layer {
            position         : absolute;
            z-index          : 1;
            top              : 0;
            bottom           : 0;
            left             : 0;
            right            : 0;
            width            : 100%;
            background-color : rgba(black, .1);
            transition       : 0.3s ease all;
        }
    }
}

.image-uploader {
    display         : flex;
    align-items     : center;
    flex-direction  : column;
    justify-content : center;
    margin-bottom   : 24px;
    border          : 1px solid rgba(0, 0, 0, 0.1);
    border-radius   : 4px;
    width           : 100%;
    min-height      : 268px;

    input {
        display : none;
    }

    p {
        margin      : 0 0 12px;
        font-size   : 16px;
        line-height : 20px;
        text-align  : center;
        color       : #000000;
    }

    span {
        opacity     : 0.3;
        margin      : 0 0 24px;
        font-size   : 16px;
        font-style  : italic;
        line-height : 100%;
        text-align  : center;
        color       : #000000;
    }
}

.multiselect-input {
    display       : block;
    margin-bottom : 24px;

    label {
        margin      : 0;
        width       : 100%;
        font-size   : 12px;
        font-weight : 500;
        line-height : 1.5;
        color       : grey;
    }

    .multiselect {
        border        : 1px solid rgba(0, 0, 0, 0.1);
        border-radius : 4px;
        width         : 100%;
        height        : 48px;
        cursor        : pointer;
        color         : #1A1A1A;

        &::after {
            content    : '';
            position   : absolute;
            top        : 22px;
            right      : 18px;
            display    : inline-block;
            width      : 10px;
            height     : 6px;
        }

        &-tags {
            padding-left : 18px;
            font-size    : 14px;
            font-weight  : 500;
            line-height  : 48px;

            &.placeholder {
                opacity : .4;
            }
        }

        &-options {
            z-index        : 1;
            top            : 8px;
            left           : -1px;
            display        : none;
            //display        : flex;
            flex-direction : column;
            box-shadow     : 0 24px 40px rgba(0, 0, 0, 0.24);
            border         : 1px solid rgba(0, 0, 0, 0.1);
            border-radius  : 4px;
            width          : calc(100% + 2px);
            padding        : 8px 0;
            background     : white;

            .option {
                height      : 40px;
                padding     : 0 18px;
                font-size   : 14px;
                font-weight : 600;
                line-height : 40px;

                &:hover {
                    background : $secondary20;
                }
            }
        }
    }
}

div.input-group {
    display          : flex;
    flex-flow        : row nowrap;
    border           : 1px solid rgba(0, 0, 0, 0.1);
    border-radius    : 4px;
    box-sizing       : border-box;
    width            : 100%;
    height           : 48px;
    background-color : white;
    font-size        : 14px;
    font-weight      : 500;
    color            : #1A1A1A;

    input {
        border        : none;
        border-radius : 0;
        box-sizing    : border-box;
        width         : 80px;
        height        : 100%;
        padding       : 8px 16px 8px 16px;
        background    : none;
        line-height   : 32px;
    }

    .input-addon {
        margin           : 0 0 0 0;
        height           : 100%;
        padding          : 8px 8px 8px 8px;
        background-color : rgba(0, 0, 0, .08);
        font-size        : 10px;
        line-height      : 32px;
        text-align       : center;
    }
}

.form-group.has-errors {
    .input-group {
        border-color : red;
    }
}

.icon-check,
.icon-triangle {
    width:18px;
    margin-right: 6px;
    position: relative;
    top: 3px;
}
