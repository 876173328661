.sidebar {
    h3 {
        margin-bottom : 16px;
    }

    .row {
        display               : grid;
        height                : 48px;
        line-height           : 48px;
        grid-template-columns : 1fr 1fr;

        span {
            font-size   : 14px;
            font-weight : 500;
            color       : $secondary40;
        }
    }

    .actions {
        margin-top  : 16px;
        border-top  : 1px solid rgba(black, .1);
        padding-top : 24px;
    }
}
