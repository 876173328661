.card {
    display       : flex;
    flex-flow     : column nowrap;
    margin        : 0 0 56px 0;
    box-shadow    : 0 4px 10px rgba(0, 0, 0, 0.04);
    border-radius : 8px;
    padding       : 40px 24px 32px 24px;
    background    : white;

    header {
        display         : flex;
        align-items     : flex-start;
        flex-flow       : column nowrap;
        justify-content : space-between;
        margin-bottom   : 8px;
        width           : 100%;
    }

    table.overview {
        margin-top    : -26px;
        margin-bottom : 0;
        box-shadow    : none;
    }
}


.card-results {
    ul {
        line-height: 20px;
        &.revisions {
            padding-left: 0;
            list-style-type: none;
            margin-top: 0;
        }
    }
}
