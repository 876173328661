.breadcrumbs {
    position  : absolute;
    top       : 16px;
    left      : 16px;
    display   : flex;
    flex-flow : row nowrap;

    span {
        margin : 0 8px;
    }

    span,
    a {
        text-decoration : none;
        color           : rgba(0, 0, 0, .5);
    }

    a:last-child {
        font-weight : bold;
        color       : black;
    }
}