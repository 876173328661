h1, h2, h3, h4, p {
    margin : 0;
}

h1 {
    display     : inline-block;
    flex-grow   : 1;
    font-size   : 32px;
    font-weight : bold;
}

h2 {
    margin      : 0 0 24px 0;
    font-size   : 24px;
    font-weight : bold;
    font-style  : normal;
    line-height : 40px;
    color       : $secondary;
}

h3 {
    margin      : 0 0 16px 0;
    font-size   : 18px;
    font-weight : bold;
    font-style  : normal;
    line-height : 18px;
    color       : $secondary;
}

h4 {
    font-size   : 14px;
    font-weight : 500;
    color       : grey;
}

p {
    font-size   : 16px;
    line-height : 160%;
}
