table.links {
    min-width: 100%;
    border-collapse: collapse;
    margin-top: 24px;

    th,
    td {
        overflow: hidden;
        padding: 12px 12px;
        line-height: 24px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            padding-left: 0 !important;
        }
    }

    th {
        font-size: 18px;
        font-weight: 700;
        border-bottom: 1px solid rgba(black, .05);
    }

    td {
        font-size: 16px;
        font-weight: 500;

        &.align-right {
            display: flex;
            flex-flow: row-reverse;
        }
    }
}


