#overlay.hide {
    display: none;
}

#overlay.show {
    position         : fixed;
    z-index          : 3;
    top              : 0;
    left             : 0;
    display          : flex;
    align-items      : center;
    flex-direction   : column;
    justify-content  : center;
    width            : 100%;
    height           : 100vh;
    background-color : rgba(0, 0, 0, 0.32);
    h2 {
        color: white;
    }
}

.modal {
    position      : absolute;
    z-index       : 10;
    top           : 10%;
    margin        : auto;
    border-radius : 12px;
    width         : 50%;
    height        : fit-content;
    background    : white;

    .header {
        z-index       : 1;
        display       : flex;
        align-items   : center;
        margin-bottom : 24px;
        box-shadow    : 0 2px 8px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.02);
        padding       : 8px 16px 8px 16px;

        img {
            float        : right;
            margin-right : 5px;
        }

        h1 {
            font-size   : 20px;
            font-weight : bold;
            font-style  : normal;
            line-height : 60px;
        }
    }

    .content {
        overflow-y       : auto;
        margin-top       : -24px;
        width            : 100%;
        max-height       : 70vh;
        padding          : 24px 16px 64px 16px;
        background-color : #fafafa;

        form {
            display   : flex;
            flex-flow : column nowrap;
            width     : 100%;
        }
    }

    .footer {
        display    : flex;
        flex-flow  : row-reverse;
        box-shadow : 0 -2px 8px rgba(0, 0, 0, 0.05), 0 -1px 0 rgba(0, 0, 0, 0.02);
        border-top : 1px solid rgba(0, 0, 0, .08);
        width      : 100%;
        padding    : 8px 16px 8px 16px;
    }
}
