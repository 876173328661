.opening-times.form-group {
    width : calc((100% - 16px) / 2);

    .input-group {
        position        : relative;
        display         : flex;
        align-items     : flex-start;
        flex-flow       : row nowrap;
        justify-content : flex-start;
        border          : none;
        background      : none;

        select.time {
            flex          : 1 1 0;
            border-radius : 0;
            padding-left  : 16px;
            text-align    : center;

            &:nth-child(1) {
                border-radius : 4px 0 0 4px;
            }

            &:nth-child(7) {
                border-radius : 0 4px 4px 0;
            }

            &:disabled {
                background-color : #f0f0f0;
            }

            &.has-errors {
                border-color : $error;
            }
        }

        .input-addon {
            border-top    : 1px solid rgba(0, 0, 0, 0.1);
            border-bottom : 1px solid rgba(0, 0, 0, 0.1);
            font-size     : 14px;
        }

        p {
            position    : absolute;
            top         : 0;
            left        : 100%;
            margin-left : 16px;
            line-height : 48px;
            color       : rgba(0, 0, 0, .48);
        }
    }
}