.tabs {
    display       : flex;
    margin-bottom : 24px;
    border-bottom : 1px solid rgba(black, .1);
    width         : 100%;
    height        : 60px;

    &.small {
        height : 48px;

        a {
            font-size   : 14px;
            line-height : 48px;
        }
    }

    a {
        padding         : 0 32px;
        font-size       : 18px;
        font-weight     : 600;
        line-height     : 60px;
        text-decoration : none;
        color           : $secondary;

        &.active {
            border-bottom : 3px solid $primary;
        }
    }
}
