.btn {
    display         : inline-flex;
    align-items     : center;
    border          : 0;
    border-radius   : 4px;
    outline         : 0;
    height          : 48px;
    padding         : 0 32px;
    background      : transparent;
    cursor          : pointer;
    font-size       : 16px;
    font-weight     : 600;
    line-height     : 48px;
    text-align      : center;
    text-decoration : none;
    color           : black;
    transition      : background-color ease-out 0.2s;

    &:focus {
        box-shadow : none;
    }

    &:hover:not(:disabled) {
        background : rgba(black, .1);
    }

    &.primary {
        background : $primary;
        color      : white;

        &:hover:not(:disabled) {
            background : darken($primary, 10%);
        }
    }

    &.secondary {
        background : rgba(black, .1);

        &:hover:not(:disabled) {
            background : rgba(black, .2);
        }
    }

    &.small {
        height      : 40px;
        padding     : 0 24px;
        line-height : 40px;
    }

    &.smallest {
        height      : 40px;
        padding     : 0 16px;
        line-height : 40px;
    }

    &.text {
        background : none;
        color      : $secondary40;

        &:hover:not(:disabled) {
            color : $secondary;
        }
    }

    &.margin-left-auto {
        margin-left : auto;
    }

    &.margin-right {
        margin-right : 16px;
    }

    &.margin-right-auto {
        margin-right : auto;
    }
}
